import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainLarge.module.scss'
import * as Framework from 'components/framework'
import useKeyPress from 'hooks/useKeyPress'
import useScrollBlock from 'hooks/useScrollBlock'
import { useWindowSize } from 'hooks/useWindowSize'
import { AramarkLogoBlack, AramarkIconRed } from 'assets/logos'
import Menu from './Menu'
import Search from '../Search'
import SubNavigation from '../SubNavigation'

/**
 * The Navigation - Main - Large Component is used to display the main navigation on pages
 */
const NavigationMainLarge = ({
  analytics,
  disableSticky,
  i18n,
  items,
  link,
  navigationRootPath,
  ...props
}) => {
  const [blockScroll, allowScroll] = useScrollBlock()
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)
  const navBar = useRef()
  const searchContainer = useRef()
  const menuContainer = useRef()
  const homePath = navigationRootPath+(i18n?.root?`/${i18n?.root?.toLowerCase()}`:'/home');
  const openMenuOverlay = (thisActiveMenu) => {
    if (activeMenu !== thisActiveMenu) {
      setActiveMenu(thisActiveMenu)
      setIsMenuActive(true)
      blockScroll()
    }
    setIsSearchActive(false)
  }

  const closeMenuOverlay = () => {
    setActiveMenu(null)
    setIsMenuActive(false)
    allowScroll()
    setIsSearchActive(false)
  }

  const handleMouseLeaveFromL1Menu = (event) => {
    const menuElement = menuContainer.current
    const hoverToElement = event.relatedTarget
    if (!(hoverToElement && menuElement && (hoverToElement !== window && menuElement?.contains(hoverToElement)))) {
      closeMenuOverlay()
    }
  }

  const toggleSearchOverlay = useCallback(() => {
    setIsSearchActive(!isSearchActive)
    if (isSearchActive) {
      allowScroll()
    } else {
      blockScroll()
    }
    setIsMenuActive(false)
    setActiveMenu(null)
  }, [allowScroll, blockScroll, isSearchActive])

  useKeyPress('Escape', () => {
    allowScroll()
    setIsSearchActive(false)
    setIsMenuActive(false)
    setActiveMenu(null)
  })

  const cmpTheme = {
    menuButton: {
      style: {
        color: 'text-color--black',
        font: 'font--gotham-medium',
        size: 'text-size--small'
      },
      type: 'button'
    },
    menuLink: {
      style: 'textMainNavLink',
      type: 'link'
    }
  }

  const { width } = useWindowSize()
  const displayLogo = width > 1299 ? AramarkLogoBlack : AramarkIconRed

  useEffect(() => {
    const handleClickOutsideMainNav = event => {
      if (isSearchActive && navBar.current && searchContainer.current && event.target !== window
        && !navBar.current.contains(event.target) && !searchContainer.current.contains(event.target)) {
        toggleSearchOverlay()
      }
    }
    document.addEventListener("click", handleClickOutsideMainNav)

    return () => {
      allowScroll()
      document.removeEventListener("click", handleClickOutsideMainNav)
    }
  }, [allowScroll, isSearchActive, toggleSearchOverlay])

  return (
    <div
      className={`${styles.large} ${disableSticky ? '' : styles.sticky}`}
      data-component='Navigation - Main - Large'
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={`${styles.bar} ${isMenuActive ? styles['menu-active'] : ''} ${isSearchActive ? styles['search-active'] : ''}`} ref={navBar}>
            {/* Home button */}
            <Framework.Anchor
              addtOnClick={() => allowScroll()}
              analytics={{
                component: analytics?.component,
                context: 'Main Nav Home'
              }}
              title={i18n?.homeText}
              href={homePath}
              theme={{
                style: 'primary',
                type: 'wrapper'
              }}
            >
              {displayLogo}
            </Framework.Anchor>
            {(items || link) && <ul>
              {items?.map((item, index) => {
                return (
                  !item.hideMainNav &&
                  <li
                    onMouseEnter={() => openMenuOverlay(index)}
                    onMouseLeave={(event) => handleMouseLeaveFromL1Menu(event)}
                    key={index}
                  >
                    {/* Collapsible L1 link */}
                    {item?.children?.length > 0 ? (
                      <Framework.Anchor
                        addtClassName={
                          (isMenuActive && activeMenu === index ? styles.isActive : '') + ' ' +
                          (item?.current || item?.active ? styles.highlight : '')
                        }
                        analytics={{
                          component: analytics?.component,
                          context: item?.title,
                        }}
                        endIcon={
                          activeMenu === index
                            ? 'chevronUpSmall'
                            : 'chevronDownSmall'
                        }
                        addtOnClick={() => allowScroll()}
                        onFocus={() => openMenuOverlay(index)}
                        onBlur={(event) => handleMouseLeaveFromL1Menu(event)}
                        href={item?.path}
                        target={item?.windowOpen ? '_blank' : null}
                        label={item?.title}
                        theme={cmpTheme?.menuLink}
                      />
                    ) : (
                      <Framework.Anchor
                        addtOnClick={() => allowScroll()}
                        analytics={{
                          component: analytics?.component,
                          context: item?.title,
                        }}
                        endIcon='chevronRightSmall'
                        href={item?.path}
                        target={item?.windowOpen ? '_blank' : null}
                        label={item?.title}
                        theme={cmpTheme?.menuLink}
                      />
                    )}
                  </li>
                )
              })}
              {link?.href && <li className={styles['section-list-header']}>
                {/* External link */}
                <Framework.Anchor
                  addtOnClick={() => allowScroll()}
                  analytics={{
                    component: analytics?.component,
                    context: analytics?.context
                  }}
                  endIcon={link?.endIcon || (link?.target === '_blank') ? 'openInNewTabSmall' : 'chevronRightSmall'}
                  href={link?.href}
                  label={link?.label}
                  startIcon={link?.startIcon}
                  target={link?.target}
                  theme={cmpTheme?.menuLink}
                />
              </li>}
            </ul>}
            <Framework.Button
              analytics={{
                action: 'Search',
                component: analytics?.component,
                context: 'Open/Close'
              }}
              content={
                <Framework.Icon icon={isSearchActive ? 'cross' : 'search'} />
              }
              label={i18n?.searchText}
              onClick={() => toggleSearchOverlay()}
              theme={{
                style: {},
                type: 'wrapper'
              }}
            />
          </div>
          {/* Subnav component */}
          <SubNavigation
            analytics={analytics}
            displaySubnav={true}
            links={items}
          />
        </div>
      </div>
      <div className={`${styles.menu} ${isMenuActive ? styles.isActive : ''}`}>
        <div className={styles.inner} >
          <div className={styles.blur}></div>
          <div
            className={styles.content}
            ref={menuContainer}
            onMouseLeave={()=>closeMenuOverlay()}
          >
            {/* Popup nav menu contents*/}
            <Menu
              activeMenu={activeMenu}
              addtOnClick={() => allowScroll()}
              analytics={analytics}
              i18n={i18n}
              items={items}
              openMenuOverlay={openMenuOverlay}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.search} ${isSearchActive ? styles.isActive : ''}`}>
        <div className={styles.inner}>
          <div className={styles.blur}></div>
          <div className={styles.content} ref={searchContainer}>
            <Search
              analytics={analytics}
              addtOnSubmit={() => allowScroll()}
              i18n={i18n}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

NavigationMainLarge.propTypes = {
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Disabled Sticky mode
   */
  disableSticky: PropTypes.bool,
  /**
   * i18n - Translation copy
   */
  i18n: PropTypes.object,
  /**
   * Items to render
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      current: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          children: PropTypes.array,
          level: PropTypes.number,
          active: PropTypes.bool,
          current: PropTypes.bool,
          title: PropTypes.string,
          path: PropTypes.string
        })
      ),
      level: PropTypes.number,
      path: PropTypes.string,
      title: PropTypes.string
    })
  ),
  /**
   * Link - Manual Link information
   */
  link: PropTypes.object
}

export default NavigationMainLarge
